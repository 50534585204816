 /* eslint-disable */
<template>
  <div id="pencarian">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-5">
          Search Here
        </h1>
        <br>
        <form>
          <div class="form-row justify-content-center align-items-center">
            <div class="col-sm-2 col-xs-3">
              <select
                v-model="selected"
                class="form-control mb-2"
                @change="onChange($event)">
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value" >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="col-sm-6 col-xs-5">
              <input
                v-model="searchText"
                type="text"
                class="form-control mb-2"
                placeholder="ketikan nama sungai, sumur, dsb">
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-primary mb-2"
                @click="searching">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="peta-cari">
      <l-map
        style="height: 100px; width: 100%"
        :zoom="zoom"
        :center="center">
        <l-tile-layer :url="url" />
      </l-map>
    </div>
    <div class="row">
      <div
        v-for="cari in hasildaftarcari.slice(0,limit)"
        :key="cari.id"
        class="col-sm-10 offset-sm-1 col-xs-12"
        style="margin-bottom: 10px"
        @click="selectItem(cari)">
        <div class="row job-item">
          <div class="col-sm-9 text-justify">
            <span>Nama : {{ cari.nama_objek }}</span>
            <br>
            <span>Kodefikasi : {{ cari.kodefikasi }}</span>
            <br>
            <span class="font-weight-light text-muted">Status : {{ cari.status_operasi }}</span>
          </div>
          <div class="col-sm-3 text-right cari-gambar">
            <b-img
              :src="cari.imageUrl"
              style="height:100px;"
              blank-color="#ccc"
              alt="image" />
            <span
              class="text-muted"
              style="font-size: smaller; align-self: flex-end;">{{ formatDate(cari.waktu) }}</span>
          </div>

        </div>
      </div>
    </div>
    <!-- BModal -->
    <div>
      <b-modal
        ref="modal-scrollable"
        centered
        scrollable
        hide-footer
        size="lg"
        :title="modalTitle"
        cancel-variant="outline-secondary">
        <div
          v-if="modalLoading"
          class="text-center">
          <b-spinner
            variant="primary"
            type="grow"
            label="Spinning"/>
        </div>
        <div v-else>
          <b-tabs
            content-class="pt-1"
            fill>
            <b-tab
              active
              title="Informasi Dasar">
              <b-table
                stacked
                :items="modalData.infoDasar"/>
            </b-tab>
            <b-tab title="Lokasi">
              <b-tablef
                stacked
                :items="modalData.lokasi"/>
              <div class="inset-map">
                <LMap
                  v-if="insetMapShowMap"
                  :zoom="insetMapZoom"
                  :center="insetMapCenter"
                  :options="insetMapMapOptions"
                  :min-zoom="insetMapMinZoom"
                  :max-zoom="insetMapMaxZoom"
                  :bounds="insetMapBounds">
                  <LTileLayer
                    :url="insetMapUrl"
                    :attribution="insetMapAttribution"/>
                  <LMarker
                    :lat-lng.sync="insetMapMarker"
                    :icon="iconDefault">
                    <LTooltip>{{ modalTitle }}</LTooltip>
                  </LMarker>
                </LMap>
              </div>
            </b-tab>
            <b-tab title="Photo">
              <div v-if="modalData.images.length > 0">
                <swiper
                  class="swiper-paginations"
                  :options="swiperOptions"
                >
                  <swiper-slide
                    v-for="(info, index) in modalData.images"
                    :key="index"
                  >
                    <b-img
                      :src="info.img"
                      fluid
                    />
                  </swiper-slide>

                  <div
                    slot="pagination"
                    class="swiper-pagination"
                  />
                  <div
                    slot="button-next"
                    class="swiper-button-next"
                  />
                  <div
                    slot="button-prev"
                    class="swiper-button-prev"
                  />
                </swiper>
              </div>
              <div v-else>
                <b-alert
                  variant="danger"
                  show
                >
                  <div class="alert-body">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    Belum ada photo.
                  </div>
                </b-alert>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// get data from json file

import {
  BImg, BModal, BTabs, BTab, BSpinner, BAlert, BTable
} from 'bootstrap-vue'
import L, { latLng, latLngBounds } from 'leaflet'
import axiosIns from '@/libs/axios'
import {
  LMap, LTileLayer, LMarker, LTooltip
} from 'vue2-leaflet'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Pencarian',
  components: {
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    BModal,
    BTabs,
    BTab,
    BTable,
    BSpinner,
    BAlert,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      selected: 'sungai',
      options: [
        { text: 'Sungai', value: 'sungai' },
        { text: 'PAH', value: 'pah' },
        { text: 'Tampungan', value: 'tampungan' },
        { text: 'Mata Air', value: 'mataAir' },
        { text: 'Sumur', value: 'sumur' }
      ],
      searchText: '',
      data: [],
      hasil: [],
      daftarcari: [],
      hasildaftarcari: [],
      limit: 51,
      pilih: null,
      zoom: 8,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      modalLoading: true,
      modalTitle: '',
      modalData: {
        infoDasar: null,
        lokasi: null,
        images: []
      },
      currentItem: false,
      markerSungai: [],
      markerPah: [],
      markerTampungan: [],
      markerMataAir: [],
      markerSumur: [],
      getData: [],
      bounds: null,
      boundsSungaiArr: [],
      boundsPahArr: [],
      boundsTampunganArr: [],
      boundsMataAirArr: [],
      boundsSumurArr: [],
      /* eslint-disable global-require */
      iconDefault: L.icon({
        iconUrl: require('@/assets/images/icons/markers/marker-icon.png'),
        iconSize: [30, 30]
      }),
      iconSungai: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-01.png'),
        iconSize: [30, 30]
      }),
      iconSungaiNonActive: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-01-nonactive.png'),
        iconSize: [30, 30]
      }),
      iconPenampunganAirHujan: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-02.png'),
        iconSize: [30, 30]
      }),
      iconPenampunganAirHujanNonActive: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-02-nonactive.png'),
        iconSize: [30, 30]
      }),
      iconTampungan: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-03.png'),
        iconSize: [30, 30]
      }),
      iconTampunganNonActive: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-03-nonactive.png'),
        iconSize: [30, 30]
      }),
      iconMataAir: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-04.png'),
        iconSize: [30, 30]
      }),
      iconMataAirNonActive: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-04-nonactive.png'),
        iconSize: [30, 30]
      }),
      iconSumur: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-05.png'),
        iconSize: [30, 30]
      }),
      iconSumurNonActive: L.icon({
        iconUrl: require('@/assets/images/icons/markers/focus-05-nonactive.png'),
        iconSize: [30, 30]
      }),
      /* eslint-enable global-require */
      // inset map option
      insetMapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      insetMapAttribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      insetMapMapOptions: {},
      insetMapCenter: [-6.906722, 107.552806],
      insetMapMarker: latLng(-6.906722, 107.552806),
      insetMapZoom: 16,
      insetMapMinZoom: 10,
      insetMapMaxZoom: 20,
      insetMapAttributionControl: false,
      insetMapZoomControl: false,
      insetMapShowMap: true,
      insetMapBounds: null,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },

  computed: {

  },

  created() {
    this.selectAir(this.selected)
  },

  methods: {
    onChange(event) {
      console.log(event.target.value, this.selected)
      this.getData = []
      console.log(this.getData)
      this.selectAir(this.selected)
    },
    selectAir(pilih) {
      switch (pilih) {
      case 'sungai':
        console.log('masuk sungai')
        this.getSungai()
        this.getDetail()
        break
      case 'pah':
        this.getPah()
        break
      case 'tampungan':
        this.getTampungan()
        break
      case 'mataAir':
        this.getMataAir()
        break
      case 'sumur':
        this.getSumur()
        break
      default:
        console.log('Method SelectAir Error')
        break
      }
    },
    getSungai() {
      axiosIns.post('/public/sungai', {
        data: {
          requireTotalCount: true,
          skip: 0,
          take: 9999,
          select: [
            'sungai.id',
            'sungai.nama_objek',
            'sungai.kodefikasi',
            'sungai.status_operasi',
            'sungai.lat',
            'sungai.lng',
            'sungai.updated_at'
          ]
        }
      })
        .then(resp => {
          const { data } = resp.data.data
          console.log(resp.data.data)
          if (data.length !== 0) {
            data.forEach(hasil => {
              this.boundsSungaiArr.push([hasil.lat, hasil.lng])
              this.getData.push({
                id: `sungai-${hasil.id}`,
                ids: hasil.id,
                position: { lat: hasil.lat, lng: hasil.lng },
                tooltip: hasil.nama_objek,
                active: hasil.status_operasi === 'Operasi',
                nama_objek: hasil.nama_objek,
                kodefikasi: hasil.kodefikasi,
                status_operasi: hasil.status_operasi,
                waktu: hasil.updated_at,
                draggable: false,
                visible: true
              })
            })
            this.setBounds()
          } else {
            this.makeToast('warning', 'Data Sungai tidak ditemukan')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPah() {
      axiosIns.post('/public/penampunganAirHujan', {
        data: {
          requireTotalCount: true,
          skip: 0,
          take: 9999,
          select: [
            'pah.id',
            'pah.nama_objek',
            'pah.kodefikasi',
            'pah.lat',
            'pah.lng',
            'pah.status_operasi',
            'pah.updated_at'
          ]
        }
      })
        .then(resp => {
          const { data } = resp.data.data
          console.log(resp.data.data)
          if (data.length !== 0) {
            data.forEach(hasil => {
              this.boundsPahArr.push([hasil.lat, hasil.lng])
              this.getData.push({
                id: `penampunganAirHujan-${hasil.id}`,
                position: { lat: hasil.lat, lng: hasil.lng },
                tooltip: hasil.nama_objek,
                active: hasil.status_operasi === 'Operasi',
                nama_objek: hasil.nama_objek,
                kodefikasi: hasil.kodefikasi,
                status_operasi: hasil.status_operasi,
                waktu: hasil.updated_at,
                draggable: false,
                visible: true
              })
            })
            this.setBounds()
          } else {
            this.makeToast('warning', 'Data penampungan air hujan tidak ditemukan')
          }
        })
        .catch(error => {
          console.log('PAH', error)
        })
    },
    getTampungan() {
      axiosIns.post('/public/tampungan', {
        data: {
          requireTotalCount: true,
          skip: 0,
          take: 9999,
          select: [
            'tampungan.id',
            'tampungan.nama_objek',
            'tampungan.kodefikasi',
            'tampungan.status_operasi',
            'tampungan.lat',
            'tampungan.lng',
            'tampungan.updated_at'
          ]
        }
      })
        .then(resp => {
          const { data } = resp.data.data
          console.log(resp.data.data)
          if (data.length !== 0) {
            data.forEach(hasil => {
              this.boundsTampunganArr.push([hasil.lat, hasil.lng])
              this.getData.push({
                id: `tampungan-${hasil.id}`,
                position: { lat: hasil.lat, lng: hasil.lng },
                tooltip: hasil.nama_objek,
                active: hasil.status_operasi === 'Operasi',
                nama_objek: hasil.nama_objek,
                kodefikasi: hasil.kodefikasi,
                status_operasi: hasil.status_operasi,
                waktu: hasil.updated_at,
                draggable: false,
                visible: true
              })
            })
            this.setBounds()
          } else {
            this.makeToast('warning', 'Data Tampungan tidak ditemukan')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getMataAir() {
      axiosIns.post('/public/mataAir', {
        data: {
          requireTotalCount: true,
          skip: 0,
          take: 9999,
          select: [
            'mata_air.id',
            'mata_air.nama_objek',
            'mata_air.kodefikasi',
            'mata_air.status_operasi',
            'mata_air.lat',
            'mata_air.lng',
            'mata_air.teknis_sistem_pengambilan_air',
            'mata_air.updated_at'
          ]
        }
      })
        .then(resp => {
          const { data } = resp.data.data
          console.log(resp.data.data)
          if (data.length !== 0) {
            data.forEach(hasil => {
              this.boundsMataAirArr.push([hasil.lat, hasil.lng])
              this.getData.push({
                id: `mataAir-${hasil.id}`,
                position: { lat: hasil.lat, lng: hasil.lng },
                tooltip: hasil.nama_objek,
                active: hasil.status_operasi === 'Operasi',
                nama_objek: hasil.nama_objek,
                kodefikasi: hasil.kodefikasi,
                status_operasi: hasil.status_operasi,
                waktu: hasil.updated_at,
                draggable: false,
                visible: true
              })
            })
            this.setBounds()
          } else {
            this.makeToast('warning', 'Data Mata Air tidak ditemukan')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getSumur() {
      axiosIns.post('/public/sumur', {
        data: {
          requireTotalCount: true,
          skip: 0,
          take: 9999,
          select: [
            'sumur.id',
            'sumur.nama_objek',
            'sumur.kodefikasi',
            'sumur.status_operasi',
            'sumur.lat',
            'sumur.lng',
            'sumur.updated_at'
          ]
        }
      })
        .then(resp => {
          const { data } = resp.data.data
          console.log(resp.data.data)
          if (data.length !== 0) {
            data.forEach(hasil => {
              this.boundsSumurArr.push([hasil.lat, hasil.lng])
              this.getData.push({
                id: `sumur-${hasil.id}`,
                position: { lat: hasil.lat, lng: hasil.lng },
                tooltip: hasil.nama_objek,
                active: hasil.status_operasi === 'Operasi',
                nama_objek: hasil.nama_objek,
                kodefikasi: hasil.kodefikasi,
                status_operasi: hasil.status_operasi,
                waktu: hasil.updated_at,
                draggable: false,
                visible: true
              })
            })
            this.setBounds()
          } else {
            this.makeToast('warning', 'Data Sumur tidak ditemukan')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searching() {
      console.log(this.getData)
      this.hasil = this.getData
        .map(item => ({
          id: item.id,
          nama_objek: item.nama_objek,
          kodefikasi: item.kodefikasi,
          status_operasi: item.status_operasi,
          position: item.position,
          tooltip: item.tooltip,
          active: item.active,
          waktu: item.waktu,
          draggable: false,
          visible: true

        }))

      this.hasildaftarcari = this.hasil

      if (this.selected === '' && this.searchText === '') {
        return this.hasildaftarcari
      }

      if (this.searchText !== '') {
        console.log(this.searchText, this.searchText.toLocaleLowerCase())
        this.hasildaftarcari = this.hasildaftarcari.filter(cari => cari.kodefikasi?.toLocaleLowerCase().indexOf(this.searchText.trim().toLocaleLowerCase()) > -1 // for TR: to include ıöüşğİ
                 || cari.nama_objek?.toLocaleLowerCase().indexOf(this.searchText.trim().toLocaleLowerCase()) > -1)
      }

      if (this.hasildaftarcari.length === 0) {
        this.makeToast('warning', 'Data yang dicari tidak ditemukan')
      }

      console.log('Filtered Count: ', this.hasildaftarcari.length, ' Total Count: ', this.hasil.length)
    },

    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: 'Peringatan',
        variant,
        solid: true
      })
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    showLongText() {
      this.showParagraph = !this.showParagraph
    },
    selectItem(cari) {
      console.log('cari', cari)
      // this.$bvModal.show('modal')
      this.alert(cari)
    },
    setBounds() {
      this.bounds = latLngBounds([...this.boundsSungaiArr, ...this.boundsPahHujanArr, ...this.boundsTampunganArr, ...this.boundsMataAirArr, ...this.boundsSumurArr])
    },
    alert(marker) {
      this.modalLoading = true
      this.modalData = {
        infoDasar: null,
        lokasi: null,
        images: []
      }
      console.log('alert', marker.id)

      const segment = marker.id.split('-')[0]
      const id = marker.id.split('-')[1]

      this.modalTitle = marker.tooltip
      this.insetMapCenter = [marker.position.lat, marker.position.lng]
      this.insetMapMarker = latLng(marker.position.lat, marker.position.lng)
      this.$refs['modal-scrollable'].show()

      axiosIns.get(`/public/${segment}/${id}/detail`).then(resp => {
        const { data } = resp
        console.log(data)

        switch (segment) {
        case 'sungai':
          this.modalData.infoDasar = [
            {
              nama_sungai: data.nama_objek, debit: `${data.manfaat_debit || 0} (l/detik)`, intake: data.jenis_intake, balai: data.balai.nama || '-', WS: data.wilayah_sungai ? data.wilayah_sungai.nama : '-'

            }
          ]
          break
        case 'penampunganAirHujan':
          this.modalData.infoDasar = [
            {
              nama_absah: data.nama_sumber_air, sistem_pengambilan_air: data.detail.teknis.sistem_pengambilan_air || '-', balai: data.balai.nama || '-', WS: data.wilayah_sungai ? data.wilayah_sungai.nama : '-'
            }
          ]
          break
        case 'tampungan':
          this.modalData.infoDasar = [
            {
              nama_tampungan: data.nama_objek, debit: `${data.manfaat_debit || 0} (l/detik)`, balai: data.balai.nama || '-', WS: data.wilayah_sungai ? data.wilayah_sungai.nama : '-'
            }
          ]
          break
        case 'mataAir':
          this.modalData.infoDasar = [
            {
              nama_mata_air: data.nama_objek, debit: `${data.manfaat_debit || 0} (l/detik)`, elevasi: `${data.detail.elevasi || 0} (mdpl)`, balai: data.balai.nama || '-', WS: data.wilayah_sungai ? data.wilayah_sungai.nama : '-'
            }
          ]
          break
        case 'sumur':
          this.modalData.infoDasar = [
            {
              nama_sumur: data.nama_sumur, kedalaman: `${data.detail.teknis.kedalaman_sumur || 0} (m)`, pumping_test: `${data.debit_awal || 0} (l/detik)`, debit_pompa: `${data.debit_andalan || 0} (l/detik)`, idle: `${data.debit_konservasi || 0} (l/detik)`, balai: data.balai.nama || '-', WS: data.wilayah_sungai ? data.wilayah_sungai.nama : '-'
            }
          ]
          break
        default:
          console.log('defualt')
          break
        }
        this.modalData.lokasi = [
          {
            provinsi: data.provinsi ? data.provinsi.nama : '-', kota: data.kota ? data.kota.nama : '-', kecamatan: data.kecamatan ? data.kecamatan.nama : '-', kelurahan: data.kelurahan ? data.kelurahan.nama : '-'
          }
        ]
        if (data.images.length > 0) {
          const { images } = data
          images.forEach(d => {
            this.modalData.images.push({
              img: d.fileUrlPath
            })
          })
          console.log('this.modalData.images', this.modalData.images)
        }

        this.modalLoading = false
      }).catch(error => {
        console.log(error)
        this.modalLoading = false
        this.makeToast('warning', 'Gagal memuat data teknis')
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date)
    },
    getDetail() {
      console.log('Masuk ke detail')
      // const { info } = this.getData
      // if (info.length) !== 0 {
      //   data.forEach(hasil => {
      //     const segment = hasil.id.split('-')[0]
      //     const id = hasil.id.split('-')[1]
      //     axiosIns.get(`/public/${segment}/${ids}/detail`).then(resp => {
      //       const
      //     })
      //   })
      // }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="swiper/css/swiper.css"></style>

<style scoped>
  .map {
    background: red;
    margin: 0;
    height: 84.40vh;
  }
  .inset-map {
    display: none;
    height: 16rem;
    width: 100%;
  }
  .cari-gambar {
    display:flex;
    flex-direction:column;
  }

#pencarian {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.job-item{
  border: solid 1px lightgray; padding: 10px
}
.job-item:hover{
  background-color:#f3f3f3;
  cursor: pointer;
}
.peta-cari{
  display: none;
  justify-content: center;
  border: 5px solid #ffff00;
}
</style>
/* eslint-enable */
